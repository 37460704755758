
import JwtService from '@/@core/auth/jwt/jwtService'
import moment from 'moment'

export default function checkToken(axiosIns) {
    let data = JSON.parse(localStorage.getItem('userData'))
	if(!data) return

    checkingTheDateAndTime()

    setInterval(() => {
        checkingTheDateAndTime()
    }, 60000)

	setInterval(() => {
		refreshToken(axiosIns)
	}, 1800000)
}
function refreshToken(axiosIns) {
    let data = JSON.parse(localStorage.getItem('userData'))
    let formData = new FormData()
    formData.append('refresh_token', data.refresh_token)

    axiosIns.post(`auth/refresh-token/${data.id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
    .then((res) => {
        let userData = JSON.parse(localStorage.getItem('userData'))
        userData.access_token = res.data.access_token
        userData.access_token_expires = res.data.access_token_expires
        userData.refresh_token = res.data.refresh_token
        userData.access_token_expDate = res.data.access_token_expDate
        localStorage.setItem('userData', JSON.stringify(userData)) 
		const jwtService = new JwtService(axiosIns)
		jwtService.setToken(res.data.access_token)
    	jwtService.setRefreshToken(res.data.access_token)
        axiosIns.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`
    })
    .catch((error) => {
        console.log(error);
    })
}


function checkingTheDateAndTime() {
    let data = JSON.parse(localStorage.getItem('userData'))
    let dateStart = new Date(data.access_token_expDate)
    let dateEnd = new Date()
    let start = moment(dateStart).format('HH:mm')
    let end = moment(dateEnd).format('HH:mm')
    
    if(dateEnd.getDate() == dateStart.getDate()) {
        if(end >= start) {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('userData')
            location.reload()
        }
    }else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userData')
        location.reload()
    }
}